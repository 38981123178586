

import React from 'react';

import './logofooter.css';


class LogoFooter extends React.Component {


render () {


return (

<div id="logofooter-wrapper">

    

    <img className="logofooter-pic" alt="logo" src="/logo-pics/logoaprilli.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logobarefoot.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logobicycle.jpg"></img>

    <img className="logofooter-pic" alt="logo" src="/logo-pics/logofiretoys.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logohenrys.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logokrom.jpg"></img>
    
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logomacaco.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logoplay.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logosundia.jpg"></img>

    <img className="logofooter-pic" alt="logo" src="/logo-pics/logosinging.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/misterlogo.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logorock.jpg"></img>

    <img className="logofooter-pic" alt="logo" src="/logo-pics/logoquax.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logocamp.jpg"></img>
    <img className="logofooter-pic" alt="logo" src="/logo-pics/logopetzl.jpg"></img>

    <img className="logofooter-pic" alt="logo" src="/logo-pics/samelogo.jpg"></img>
    


</div>

)

}

}


export default LogoFooter;